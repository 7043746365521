import { ServerError } from "../common/Errors";

const host = process.env.API_HOST;

  /**
   * Make a GET call.
   * @param {String} path
   * @returns {Promise<any>}
   */
  const get = async (path, headers) => {
    return await fetch(`${host}${path}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers
      },
    })
    .then(response => Promise.all([response.ok, response.json()]))
    .then(([ok, body]) => handleErrors(ok, body))
  };

  function handleErrors(ok, body) {
    if (!ok) {
        throw new ServerError(body.error.errorMessage);
    }
    return body;
}

  /**
   * Make a POST call.
   * @param {String} path
   * @param {Object} body
   * @returns {Promise<any>}
   */
  const post = async (path, body, headers) => {
    return await fetch(`${host}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers
      },
      body: JSON.stringify(body)
    })
    .then(response => Promise.all([response.ok, response.json()]))
    .then(([ok, body]) => handleErrors(ok, body))
  };

  /**
   * Make a PUT call.
   * @param {String} path
   * @param {Object} body
   * @returns {Promise<any>}
   */
  const put = async (path, body, headers) => {
    return await fetch(`${host}${path}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers
      },
      body: JSON.stringify(body)
    })
    .then(response => Promise.all([response.ok, response.json()]))
    .then(([ok, body]) => handleErrors(ok, body))
  };

  /**
   * Make a DELETE call.
   * @returns {Promise<any>}
   */
  const del = async (path, headers) => {
    return await fetch(`${host}${path}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers
      }
    })
    .then(response => Promise.all([response.ok, response.json()]))
    .then(([ok, body]) => handleErrors(ok, body))
  };


class Client {
  idToken = null;

  headers = {
    'api-key': process.env.API_KEY,
    'client-id': process.env.CLIENT_ID
  }
  
  getHeaders = () => ({
    ...this.headers,
    Authorization: this.idToken
  })

  /*
  API client code
  */

  /**
   * Fetch the Summary
   * @returns {Promise<import("rdd-shared").Summary>}
   */
  getSummary = async () => await get('/user/summary', this.getHeaders());

  /**
   * During create account step: check that the parameteres are valid. 
   * @param {import("rdd-shared").CheckRegistrationRequest} request 
   * @returns {Promise<import("rdd-shared").CheckRegistrationResponse>}
   */
  checkRegistration = async (request)  => await post('/user/checkregistration', request, this.getHeaders());


  /**
   * Create account
   * @param {import("rdd-shared").CreateAccountRequest} request
   * @returns {Promise<import("rdd-shared").CreateAccountResponse>}
   */
  createAccount = async (request) => await post('/user/createaccount', request, this.getHeaders())
}

var api = new Client();

export { api } ;
