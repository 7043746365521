import React, { useState, useEffect, useContext, createContext } from "react";

import { app } from './../../common/firebase/FirebaseApp';

import { getState, getAuthState } from './Context';
import { auth } from "firebase";

export class InvalidAuthError { }
export class UserNotFoundError { }

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {

  const [authState, setAuthState] = getAuthState();
  const [state, setState] = getState();

  const setUser = (user?: firebase.User) => {  
    console.log('Setting Firebase user...');
    setAuthState(state => ({ ...state, firebaseUser: user }));
    setState(state => ({...state, isLoggedIn: user !== null}));
  }

  /**
   * Sign in .
   * @param {string} email Email
   * @param {string} password Password
   * @throws {InvalidAuthError} 
   * @throws {UserNotFoundError}
   */
  const signin = (email, password) => {
    return app
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(response => {
        console.log('Firebase Auth response : ' + response);
        setUser(response.user);
        return response.user;
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        switch (errorCode) {
          case 'auth/wrong-password':
            throw (new InvalidAuthError());
          case 'auth/user-not-found':
            throw (new UserNotFoundError());
        }
        throw new Error(errorMessage);
      });
  };

  const signup = (email, password) => {
    return app
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(response => {
        setUser(response.user);
        return response.user;
      });
  };

  const signout = () => {
    return app
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
      });
  };

  const changePassword = newPassword => {
    return app
      .auth()
      .currentUser.updatePassword(newPassword)
      .then(() => {
        return true;
      });
  }

  const sendPasswordResetEmail = email => {
    return app
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return app
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    /**
     * This will trigger on sign in, sign out, and on ID token expiry. 
     */
    const unsubscribe = app.auth().onIdTokenChanged(user => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user: authState.firebaseUser,
    setUser,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    isLoggedIn: state.isLoggedIn
  };
}

export default useAuth;