import moment from "moment";

/**
 * User Stub
 * @type {import("rdd-shared").User}
 */
export const user = {
  id: "088",
  registrationToken: "34f9fb072b7462ec69516d3905081db350053b820f5584c64854301c65518a79500e5c950bd12a1c07c5d807cc4266e47126762a8d2b90f81e97c563c5a1fd3c",
  isSynced: false,
  isAccountCreated: true,
  type: "owner",
  bankDetails: {
    bankAddress: "Torremar, Torrevieja",
    iban: "ES51 0081 1463 9100 0147 2352",
    bankName: "Sabadell",
    bic: "BSABESBB",
    accountName: "Vyacheslav Basharov"
  },
  email: "slavbacharov@gmail.com",
  inviteEmailSent: true,
  uid: "Y5LY0flTbCesnmxUbUV7zeiD9MB3",
  firstName: "Vyacheslav",
  fiscalDetails: {
    address: "Calle Los Geranios, 140, El Rosario, Marbella, 29604",
    identifier: "Y3427323-S",
    name: "Vyacheslav Basharov"
  },
  phoneNumber: "0046705529680",
  properties: [
    "RDR183"
  ],
  contract: {
    dateSigned: moment("2020-06-18T00:00:00.000Z").toDate(),
    pdfUrl: "https://signrequest-pro.s3.amazonaws.com/pdfs/2020/06/18/82e7e95a649f91f08e8d7eeeb77aad66b3eccf74/landlord_contract_signed.pdf?AWSAccessKeyId=AKIAIFC5SSMNRPLY3AMQ&Signature=ZAX1loGDOW5556su4%2B5qmXjbBCg%3D&Expires=1592478131",
    signRequestId: "VqmRYqOCnfAlbHQ5EdQK"
  },
  lastName: "Basharov"
}

/**
 * Properties Stub
 * @type {Array<import("rdd-shared").Property>}
 */
export const properties = [
  {
    id: "RDR183",
    numberOfBedrooms: 2,
    listingUrl: "https://www.reekanddalli-properties.com/en/1784840",
    pricing: {
      maxPrice: 250,
      minPrice: 120
    },
    lodgifyId: "270938",
    numberOfBathrooms: 2,
    rdrId: "RDR183",
    imageUrl: "//l.icdbcdn.com/oh/5a312065-2a7a-4cd7-b1bd-12d3dc3cd063.jpg?f=32&w=400",
    location: {
      latitude: 36.516941,
      longitude: -4.963711
    },
    type: "Apartment",
    address: "Calle Los Geranios, 140, El Rosario, Marbella, 29604"
  }
]

/**
 * Calendar Stub
 * @type {import("rdd-shared").Calendar}
 */
export const calendar = {
  booked: [
    {
      id: 'RDR2347392256',
      type: 'booked',
      bookedDate: '2019-11-11',
      startDate: "2020-01-14",
      endDate: "2020-01-24",
      name: "Pedro Gutierrez",
      numberOfGuests: 2,
      ownerShare: "€2,540.00",
      payoutDate: "2020-02-20",
      attachments: [
        {
          name: 'Tenant contract',
          url: "https://drive.google.com/open?id=1fBhQUIPDTi1U6oh2pkKMPuDLMlGOFI4l68V2rs0sKQA"
        }],
      comment: "Test"
    },
    {
      id: 'RDR2047393756',
      type: 'booked',
      bookedDate: '2019-12-15',
      startDate: "2020-01-24",
      endDate: "2020-02-07",
      name: "Pablo Motos",
      numberOfGuests: 2,
      ownerShare: "€2,540.00",
      payoutDate: "2020-02-20",
      attachments: [],
      comment: "Test"
    },
    {
      id: 'RDR2047592756',
      type: 'booked',
      bookedDate: '2019-11-20',
      startDate: "2020-02-08",
      endDate: "2020-02-10",
      name: "Armin Halls",
      numberOfGuests: 2,
      ownerShare: "€2,540.00",
      payoutDate: "2020-02-20",
      attachments: [],
      comment: "Test"
    },
    {
      id: 'RDR2047592799',
      type: 'booked',
      bookedDate: '2019-11-28',
      startDate: "2020-07-14",
      endDate: "2020-07-16",
      name: "Bob Bob",
      numberOfGuests: 2,
      ownerShare: "€2,540.00",
      payoutDate: "2020-02-20",
      attachments: [],
      comment: "Test"
    },
    {
      id: 'RDR2047592736',
      type: 'booked',
      bookedDate: '20120-02-20',
      startDate: "2020-07-16",
      endDate: "2020-07-20",
      name: "Alice Bob",
      numberOfGuests: 2,
      ownerShare: "€2,540.00",
      payoutDate: "2020-02-20",
      attachments: [],
      comment: "Test"
    },
  ],
  ownerStay: [
    {
      type: 'ownerStay',
      startDate: "2020-03-01",
      endDate: "2020-03-07",
      name: "Jesus Verdes"
    },
    {
      type: 'ownerStay',
      startDate: "2020-04-01",
      endDate: "2020-05-01",
      name: "Jesus Verdes"
    }
  ],
  blocked: [
    {
      type: 'blocked',
      startDate: "2020-01-01",
      endDate: "2020-01-14",
      name: "Jesus Verdes"
    },
    {
      type: 'blocked',
      startDate: "2020-06-05",
      endDate: "2020-06-21",
      name: "Jesus Verdes"
    }
  ]
}