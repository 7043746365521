import React from "react";

/** @jsx jsx */
import { jsx, css } from "@emotion/core";

import { SmallButtonStyle, ButtonStyle } from "./Buttons";

/*
Form components. Input fields, etc
*/
export const TextInput = props => (
  <input
    type="text"
    css={css`
      margin-top: 1em;
      padding: 0.7em;
      box-sizing: border-box;
      border: 2px solid #dbdadc;
      border-radius: 5px;
      outline-style: none;
      min-width: 250px;
      font-size: 12pt;
      :focus {
        border-color: black;
      }
      :disabled {
        background-color: #EAEAEA;
      }
    `}
    {...props}
  />
);

export const SubmitInput = allProps => {
  const { isValid, ...props } = allProps;
  return (
    <input
      type="submit"
      disabled={isValid ? false : true}
      css={css`
        ${ButtonStyle} :disabled {
          opacity: 0.3;
          cursor: default;
        }
        margin-top: 2em;
      `}
      {...props}
    />
  );
};

/**
 * A simple text input form.
 * @param {{
 *  placeholder: String,
 *  submitText: String,
 *  isValid: Boolean,
 *  onChange: function(React.ChangeEvent<HTMLInputElement>): void,
 *  onSubmit: function(React.FormEvent<HTMLFormElement>): void
 *  }} props
 */
export const TextForm = props => (
  <form onSubmit={props.onSubmit}>
    <TextInput placeholder={props.placeholder} onChange={props.onChange} />
    <SubmitInput
      isValid={props.isValid}
      type="submit"
      value={props.submitText}
    />
  </form>
);

export const Dropdown = props => (
  <select css={css`
    background-color: white;
    /* box-shadow: 2px 2px 20px #86868666; */
    outline: none;
    font-weight: bold;
    border: none;
    padding: 2em;
    font-size: 14pt;
    margin: -6px;
  `}
    value={props.value}
    onChange={props.onChange}>
      {props.items.map((item, idx)=>(
        <option css={css`
        `} value={idx}>{item}</option>
      ))}
  </select>
)