import { getState } from "./Context";
import { User, Property, Summary } from 'rdd-shared';

const useData = () => {
    const [state, setState] = getState();

    /**
     * Set all from get summary.
     * @param {import("rdd-shared").Summary} summary The new summary 
     */
    const setSummary = (summary: Summary) => {
        // console.log(`Setting summary. ${JSON.stringify(summary)}`)
        setState(state => ({
             ...state, 
             data: { 
                ...state.data, 
                user: summary.user, 
                properties: summary.properties,
                calendar: summary.calendar,
                selectedProperty: summary.properties[0]
            } }));
    }

    // const setUser = (user: User) => {
    //     setState(state => ({ ...state, data: { ...state.data, user } }))
    // }

    // const setProperties = (properties: Array<Property>) => {
    //     setState(state => ({ ...state, data: { ...state.data, properties } }))
    // }

    // const setSelectedProperty = (selectedProperty: Property) => {
    //     setState(state => ({ ...state, data: { ...state.data, selectedProperty } }))
    // }

    // const setCalendar = (properties: Calendar) => {
    //     setState(state => ({ ...state, data: { ...state.data, properties } }))
    // }

    return {
        // calendar: state.data.calendar,
        user: state.data.user,
        properties: state.data.properties,
        calendar: state.data.calendar,
        selectedProperty: state.data.selectedProperty,
        setSummary
    }
};

export default useData;