import React from 'react'
import { Column } from '../../../components/Layout'
import { H1, P } from '../../../components/Text'

const DownloadIcon = '../../../assets/icons/download.svg';
import { Card } from '../../../components/Card';
const CloseIcon = '../../../assets/icons/close.svg';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import useExpensesPopup from '../../../common/state/usePopup';
import { ImageButton } from '../../../components/Buttons';
import { Table, TableRow, TableCell, TableHeader } from '../../../components/Table';

export default (props) => {
    const { setExpensesPopupShown } = useExpensesPopup();

    return (
        <Card css={css`margin: auto;max-width: 70%;`}>
            <ImageButton onClick={() => setExpensesPopupShown(false)} css={css`position: absolute; align-self: flex-end;`} src={CloseIcon} />
            <Column>
                <H1>Other Expense Invoices</H1>
                <P>Here is a list of Reek & Dalli Invoices for expenses other than those under a rental management concept.
To download rental management invoices, click on the associated rental from the calendar view.</P>
                <Table>
                    <TableHeader>Invoice Number</TableHeader>
                    <TableHeader>Concept</TableHeader>
                    <TableHeader>Download</TableHeader>
                    <TableRow>
                        <TableCell>006</TableCell>
                        <TableCell>Purchase of new toaster</TableCell>
                        <TableCell><img src={DownloadIcon} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>0025</TableCell>
                        <TableCell>Clean after owner stay</TableCell>
                        <TableCell><img src={DownloadIcon} /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>0067</TableCell>
                        <TableCell>Fix of boiler</TableCell>
                        <TableCell><img src={DownloadIcon} /></TableCell>
                    </TableRow>
                </Table>
            </Column>
        </Card>
    )
}