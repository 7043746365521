import React, { Suspense, lazy, useEffect, useState } from 'react'
import { Switch, Route, Redirect, Link, useLocation } from 'react-router-dom'
import useSession from '../common/state/useSession';
// Hooks
import useErrorBoundary from "use-error-boundary";

// Routes
const Home = lazy(() => import('./Home'));
const Account = lazy(() => import('./Account'));
const NewClient = lazy(() => import('./Admin/NewClient'));

// UI
import { motion, AnimatePresence } from "framer-motion";
import { Column, Row } from '../components/Layout';
import { Header } from '../components/Header';

// Assets 
import logo from './../assets/logo_header.png';

/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { P, H1, Small } from '../components/Text';
import useExpensesPopup from '../common/state/usePopup';
import ExpensesPopup from './Home/components/ExpensesPopup';
import useCalendar from '../common/state/useCalendar';
import { DateRangePopup } from './Home/components/DateRangePopup';
import { Loading } from '../components/Loading';
import { useAuth } from '../common/state/useAuth';
import Login from './Login';

import { api } from "../api/api";
import useData from '../common/state/useData';

const Footer = props => {

  return (
    <Column css={css`padding: 2em;box-sizing:border-box;`} center>
      <Small secondary bold>© Reek & Dalli 2020</Small>
      <Small secondary>Version: {process.env.npm_package_version}</Small>
    </Column>
  )
}

export default function MainApp() {
  const { user: firebaseUser, signout, isLoggedIn } = useAuth();
  const { selectedRange } = useCalendar();
  const location = useLocation();
  const { user, properties } = useData();
  const [loadingMessage, setLoadingMessage] = useState('');

  const selectedProperty = properties ? properties[0] : null;

  // Motion transitions
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 1.05
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 0.95
    }
  };

  const {
    ErrorBoundary, // class - The react component to wrap your children in. This WILL NOT CHANGE
    didCatch, // boolean - Whether the ErrorBoundary catched something
    error, // null or the error
    errorInfo // null or the error info as described in the react docs
  } = useErrorBoundary()

  const logout = async () => {
    await signout();
  }

  return (
    <div css={css`
      height: auto;
      width: 100%;
      min-height: 100%;
      background-color:#FAFBFB;
    `}>
      <div css={css`
      height: 100%;
      min-height: 100%;
      width: 100%;
      max-width: 1250px;
      margin:auto;
      display: grid;
      grid-template-rows: auto 1fr auto;
      grid-template-columns: 100%;
    `}>
        <Header>
          <Row css={css`margin: 0 2em;`} wrap middle between>
            <Row middle css={css`width: auto;flex: 1;`}>
              <img css={css`height: 40px;`} src={logo} />
              <P bold css={css`margin-left: 10px;color: #363565;`}>Owner Dashboard</P>
            </Row>
            {/* TODO: Property switcher */}
            <div css={css`
            background-color: white;
            border-radius: 10px;
            margin: 0 2em;
            display: flex;
          `}>
              <Row css={css`
                background: #FFFFFF;
                /* border: 2px solid #DFDFDF; */
                box-shadow: 0px 4px 11px rgba(121, 121, 121, 0.1);
                border-radius: 10px;
              `} center>
                <div css={css`padding: 15px 10px;`}><P secondary css={css`margin:0;font-size: 10pt;`}>Your property</P></div>
                <div css={css`padding: 15px 10px;border-left: 1px solid #F0F2F7;`}>
                  <P bold css={css`margin:0;font-size: 10pt;`}>{selectedProperty ? selectedProperty.rdrId : 'No property selected'}</P>
                </div>
              </Row>
            </div>
            <ul css={css`
            padding: 0;
            flex: 1;
            width: fit-content;
            list-style: none;
            display: flex;
            align-self:flex-end;
            justify-content: flex-end;
                > li a {
                text-decoration: none;
                color: #535272;
                font-weight: bold;
                font-size: 10pt;
                  :hover {
                      color: #2F6E72;
                  }
                }
                > li {
                :not(:first-of-type) {
                    margin-left: 40px;
                }
               
            }
            `}>
              {user.type === 'admin' && <li><Link to="/newclient">Create new client</Link></li>}
              <li><Link to="/">Dashboard</Link></li>
              <li><Link to="/account">Account</Link></li>
              <li onClick={logout}><a href="">Logout</a></li>
            </ul>
          </Row>
        </Header>

        {/*
        Code-splitting.
        https://reactjs.org/docs/code-splitting.html#reactlazy

        We're lazy loading each component, and using a fallback when loading it.

        */}

        <ErrorBoundary>
          {!didCatch &&
            <Suspense fallback={
              <Column center middle>
                <Loading css={css`height: 100%;margin:auto;`} message={loadingMessage} />
              </Column>}>
              <Switch location={location} key={location.pathname}>
                <Route exact path="/newclient">
                  {
                    isLoggedIn && firebaseUser && user.type === 'admin' && <NewClient />
                  }
                </Route>
                <Route exact path="/account">
                  {
                    isLoggedIn && firebaseUser && <Account />
                  }
                </Route>
                <Route exact path={['/', '/home']}>
                  {
                    isLoggedIn && firebaseUser && <Home />
                  }
                </Route>
              </Switch>
            </Suspense>
          }
          {didCatch && <H1>{error}{errorInfo}</H1>}
        </ErrorBoundary>
        <Footer />
      </div>
    </div>
  )
}
