import React, { useState, useEffect } from 'react';
import { H1, H2, H3, P, Large, Small } from '../../components/Text';
import { PrimaryButton } from '../../components/Buttons';
import { ClassicSpinner } from 'react-spinners-kit';

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TextInput, SubmitInput } from '../../components/Form';
import { Link } from 'react-router-dom';

import { Header } from '../../components/Header';

import logoFull from '../../assets/logo_header_full.png';
import illustration from '../../assets/welcome_bg.png';
import { Row, Column } from '../../components/Layout';
import { Card } from '../../components/Card';

import { useHistory } from 'react-router-dom';
import useSession from '../../common/state/useSession';
import useAuth, { InvalidAuthError, UserNotFoundError } from '../../common/state/useAuth';

export const LoginForm = props => {

  const { user, signin } = useAuth();

  const [isValid, setValid] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorText, setErrorText] = useState('');

  const onSubmit = async event => {
    event.preventDefault();
    setIsSigningIn(true);
    try {
      await signin(email, password);
      console.log('HMMMM');

      setIsSigningIn(false);
    } catch (error) {
      console.log(error);
      console.log('ERR');

      if (error instanceof InvalidAuthError) {
        setErrorText('Invalid email/password');
      } else if (error instanceof UserNotFoundError) {
        setErrorText('User not found, try again.')
      }
      setIsSigningIn(false);
    }
    console.log(`The user is ${user}`)

  }

  useEffect(() => {
    validate();
  });
  
  const validate = () => {
    setValid(email.length > 0 && password.length > 0);
  }

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const onEmailUpdated = event => {
    setEmail(event.target.value);
  };

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const onPasswordUpdated = event => {
    setPassword(event.target.value);
  };

  return (
    <form onSubmit={onSubmit} css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        `}>
      <TextInput type="email" placeholder="Email" onChange={onEmailUpdated} autoComplete="username"/>
      <TextInput type="password" placeholder="Password" onChange={onPasswordUpdated} autoComplete="current-password" />
      <SubmitInput
        isValid={isValid}
        type="submit"
        value={isSigningIn ? 'Logging in...' : 'Login'}
      />
      {errorText.length > 0 &&
        <div css={css`padding: 5px 10px; color: #BA3F1D;`}>
          <P>{errorText}</P>
        </div>
      }
    </form>
  )
}

export const Login = props => {
  const history = useHistory();

  return (
    <div css={css`
            background-color: white;
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-rows: auto 1fr auto;
        `}>

      <Header css={css`background-color: white;`}>
        <Column left middle>
          <img css={css`height: 40px;`} src={logoFull} />
        </Column>
        <Column css={css`
          flex-grow: 1;
          `} right>
          <ul css={css`
            width: fit-content;
            list-style: none;
            align-self:flex-end;

            > li a {
              text-decoration: none;
              color: #00B8BC;

              :not(:first-of-type) {
                  margin-left: 10px;
              }
              :hover {
                  color: #2F6E72;
              }
              }
            `}>
            <li css={css`display:grid;grid-gap: 1em;grid-template-columns: auto auto;`}>
              <a href="https://reekanddalli.com"><Small bold>Reek & Dalli Website</Small></a>
              <a href="https://reekanddalliproperties.com"><Small bold>All properties</Small></a>
            </li>
          </ul>
        </Column>
      </Header>

      {/* The login box */ }
      <Column center 
        css={css`
                width: 100%;
                background-repeat: no-repeat;
            `}>
        <Column css={css`
          margin-top: 8em;`}>
          <Column center>
              <H2 css={css`color: black;margin: 0;`}>Owner Dashboard</H2>
              <H3 css={css`color: gray;margin: 0;`}>Login</H3>
          </Column>
            <img css={css`height: 80px; width: auto;`} src={illustration} />
              <div css={css`
              background: #FFFFFFD8;
              border-radius: 10px;
              box-shadow: 2px 2px 20px #86868666;
              display: flex;
              flex-direction: column;
              align-items: center;
              `}
              >
                <LoginForm />
              </div>
        </Column>
      </Column>

      {/* Simple footer at the bottom */ }
      <footer css={css`
        margin: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        bottom:0;
        margin-bottom: 1em;`}>
        <Small secondary bold>© Reek & Dalli 2020</Small>
        <Small secondary>Version: {process.env.npm_package_version}</Small>
      </footer>
    </div >
  );
}

export default Login;