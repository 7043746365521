/**
 * The global theme for the dashboard. 
 * - Fonts
 * - Colors (brand, primary, secondary, accent)
 * - Text Colors (primary, secondary)
 * - Spacing
 */
export default {
    colors: {
        primary: '#00B8BC',
        accent1: '#E08275',
        accent2: '#FFBABA', 
        accent3: '#B5B5FF',
        success: '#00BC84'
    },
    textColors: {
        primary: '#363565',
        secondary: '#535272',
        darkGrey: '#414141'
    },
    fontSizes: {
        h1: '2.3em', 
        h2: '1.5em', 
        h3: '1.2em', 
        p: '1em', 
        small: '0.8em' 
    }
  }