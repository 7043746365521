/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { P, Small } from "./Text";
import { Icon } from '@iconify/react';
import { useTheme } from 'emotion-theming';

export const ButtonStyle = `
    padding: 1em 2em;
    background-color: #00B8BC;
    font-size: 10pt;
    border-radius: 4px;
    border: none;
    color: white;
    font-weight: bold;
    outline: none;
    width: fit-content;
    transition: background-color 0.2s ease-in;
    
    :hover {
        background-color: #007570;
        cursor: pointer;
    }
    :active {
        background-color: #07635A;
        transform: scale(0.95);
    }
    font-family: inherit;
`;

export const SmallButtonStyle = `
${ButtonStyle}
  padding: 0.3em 0.6em;
`;

export const PrimaryButton = allProps => {
  const { round, ...props } = allProps;
  return (
    <button
      css={css`
    ${ButtonStyle}
    ${round ? `border-radius: 20px;` : ``}
  `}
      {...props} />
  )
}

export const SmallButton = allProps => {
  const { round, ...props } = allProps;

  return (
    <button
      css={css`
      ${SmallButtonStyle}
      ${round ? `border-radius: 20px;` : ``}
    `}
      {...props} />
  )
}

export const ImageButton = allProps => {

  return (
    <img css={css`
      :hover {
        opacity: 0.5;
        transform: scale(0.8);
        cursor: pointer;
      }
    `}
      {...allProps} />
  )
}
/**
 * Secondary Button. 
 * Rectangular, light.
 * @param {object} allProps Properties
 * @param {any} allProps.icon
 * @param {string} allProps.text
 * @param {string=} allProps.url
 */
export const SecondaryButton = allProps => {
  const { icon, text, url, ...props } = allProps;
  const theme = useTheme();
  return (
    <a css={css`text-decoration:none;`} href={url}>
      <button css={css`
      background: #FFFFFF;
      border: 2px solid #DFDFDF;
      box-shadow: 0px 4px 11px rgba(121, 121, 121, 0.1);
      border-radius: 10px;
      width: 100%;
      display: flex;
      padding: 0.2em 1em;
      font-family: inherit;
      transition: all 0.2s ease-in;
      font-size: ${theme.fontSizes.p};
      :hover {
        cursor: pointer;
        box-shadow: 0px 4px 13px rgba(61, 61, 61, 0.2);
      }
      :active {
        box-shadow: 0px 4px 4px rgba(121, 121, 121, 0.1);
      }
    `}
        {...props}>
        <Small bold css={css`margin-right: 3em;flex-grow: 1;`}>{text}</Small>
        <Icon icon={icon} style={{ fontSize: '20px', alignSelf: 'center' }} />
      </button>
    </a>
  )
}