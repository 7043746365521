import { getState } from "./Context";

const useCalendar = () => {
    const [state, setState] = getState();

    /**
     * Set selected date range (not booking), for popup. 
     * @param {import("rdd-shared").DateRange} selectedRange The new summary 
     */
    const setSelectedRange = selectedRange => {
        console.log(`Setting date range. ${JSON.stringify(selectedRange)}`)
        setState(state => ({ ...state, selectedRange, selectedBooking: null }));
    }

    /**
     * Set selected booking, for popup. 
     * @param {import("rdd-shared").Booking} booking The booking
     */
    const setSelectedBooking = booking => {
        setState(state => ({ ...state, selectedBooking: booking, selectedRange: null }));
    }

    /**
     * Set the selected calendar year. 
     * @param {number} selectedYear The year
     */
    const setSelectedYear = selectedYear => {
        console.log(`Setting year. ${JSON.stringify(selectedYear)}`)
        setState(state => ({ ...state, selectedYear }));
    }

    return {
        calendar: state.data.calendar,
        selectedRange: state.selectedRange,
        selectedBooking: state.selectedBooking,
        selectedYear: state.selectedYear,
        setSelectedRange,
        setSelectedYear,
        setSelectedBooking,
    }
};

export default useCalendar;