//@ts-check
import React, { createContext, useState, useContext, useEffect } from "react";

// Import stubs
import { user, properties, calendar } from '../../stubs/data';

import { State, AuthState } from 'lib';

/*
Persisted state
==========
*/

/**
 * @type {React.Context<[State, function(State):void]>}
 */
//@ts-ignore
const RDContext = createContext([{}, () => { }]);

/**
 * ALL STUBS (LOCAL) STATE
 * @type {State}
 */
const localState = {
  data: { user, properties, calendar},
  isInit: true,
  isLoading: false,
  isLoggedIn: false,
  isExpensesPopupShown: false
}

/**
DEVELOPMENT STATE (on Firebase), some mock data
@type {State}
*/
const devState = {
  data: { calendar},
  isInit: true,
  isLoading: false,
  isLoggedIn: false,
  isExpensesPopupShown: false
}

/**
PRODUCTION STATE (on Firebase), everything is blank.
@type {State}
*/
const prodState = {
  isInit: true,
  isLoading: false,
  isLoggedIn: false,
  isExpensesPopupShown: false
}

/**
 * @returns {State|null}
 */
const getPersistedState = () => {
  var persistedStateString = localStorage.getItem('rd-owner-portal-state');
  if (persistedStateString) {
    return JSON.parse(persistedStateString)
  }
  return null;
}

/**
 * Use persisted storage state, as seen in:
 * https://dev.to/selbekk/persisting-your-react-state-in-9-lines-of-code-9go
 * 
 * @param {string} key 
 * @param {State} defaultValue Default state
 * @returns {[State, function(function (State): State):void]}
 */
function usePersistedState(key, defaultValue) {
  const [state, setState] = React.useState(
    JSON.parse(localStorage.getItem(key)) || defaultValue
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  
  return [state, setState];
}


const RDProvider = props => {
  // Assign default state based on environment.
  // Useful to mock certain data, but not others.

  var defaultState;
  switch (process.env.ENV) {
    case 'local':
      defaultState = localState;
      break;
    case 'dev':
      defaultState = devState;
      break;
    case 'prod':
      defaultState = prodState;
      break;
  }

  /**
   * @type {[State, React.Dispatch<React.SetStateAction<State>>]}
   */
  const [state, setPersistedState] = usePersistedState('rd-owner-portal-state', defaultState);

  return (
    <RDContext.Provider value={[state, setPersistedState]}>
      {props.children}
    </RDContext.Provider>
  );
};

/**
 * @returns {[State, function(function (State): State):void]}
 */
const getState = () => {
  const [state, setState] = useContext(RDContext);
  // console.log(`State: ${JSON.stringify(state)}`);
  //@ts-ignore
  return [state, setState];
};


/*
Auth state
==========
*/

/**
 * @type {React.Context<[AuthState, function(AuthState):void]>}
 */
//@ts-ignore
const AuthContext = createContext([{}, () => { }]);

const AuthProvider = props => {
  /**
   * @type {[AuthState, React.Dispatch<React.SetStateAction<AuthState>>]}
   */
  const [state, setState] = useState({});

  return (
    <AuthContext.Provider value={[state, setState]}>
      {props.children}
    </AuthContext.Provider>
  );
};

/**
 * This state is only in memory. Use this whenever data should NOT be persisted.
 * (like Firebase User).
 * @returns {[AuthState, function(function (AuthState): AuthState):void]}
 */
const getAuthState = () => {
  const [state, setState] = useContext(AuthContext);
  // console.log(`State: ${JSON.stringify(state)}`);
  //@ts-ignore
  return [state, setState];
};

export { RDContext, RDProvider, AuthProvider, getState, getAuthState };
