
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useTheme } from 'emotion-theming';

export const H1 = allProps => {
    const { secondary, bold, ...props } = allProps;
    const theme = useTheme();

    return (
        <h1 css={css`
            font-size: ${theme.fontSizes.h1};
            margin: 5px 0;
            color: ${theme.textColors.primary};
    `}
            {...props} />
    );
}

export const H2 = allProps => {
    const { secondar, bold, ...props } = allProps;
    const theme = useTheme();

    return (
        <h2 css={css`
        font-size: ${theme.fontSizes.h2};
        margin: 5px 0;
        color: ${props.secondary ? theme.textColors.secondary : theme.textColors.primary};
    `} {...props} />
    );
}

export const H3 = allProps => {
    const { secondary, bold, color, ...props } = allProps;
    const theme = useTheme();

    return (

        <h3 css={css`
        margin: 5px 0;
        font-size: ${theme.fontSizes.h3};
        color: ${color ? color : secondary ? theme.textColors.secondary : theme.textColors.primary};
    `} {...props} />
    );
}

export const H4 = allProps => {
    const { secondary, bold, ...props } = allProps;
    const theme = useTheme();

    return (
        <h4 css={css`
            margin: 5px 0;
            color: ${props.secondary ? theme.textColors.secondary : theme.textColors.primary};
    `} {...props} />
    );
}

export const H5 = allProps => {
    const { secondary, bold, ...props } = allProps;
    const theme = useTheme();

    return (
        <h5 css={css`
            margin: 5px 0;
            color: ${props.secondary ? theme.textColors.secondary : theme.textColors.primary};
    `} {...props} />
    );
}

export const P = allProps => {
    const { secondary, bold, center, color, ...props } = allProps;
    const theme = useTheme();

    return (

        <p css={css`
        font-size: ${theme.fontSizes.p};
        margin: 5px 0;
        color: ${color ? color : secondary ? 'grey' : theme.textColors.darkGrey};
        font-weight: ${ bold ? 'bold' : 'normal'};
        text-align: ${center ? 'center' : 'initial'};
     `} {...props} />
    );
}

export const Small = allProps => {
    const { secondary, bold, center, ...props } = allProps;
    const theme = useTheme();

    return (
        <small css={css`
        margin: 5px 0;
        font-size: ${theme.fontSizes.small};
        color: ${props.secondary ? theme.textColors.secondary : theme.textColors.primary};
        font-weight: ${ bold ? 'bold' : 'normal'};
        text-align: ${center ? 'center' : 'initial'};
     `} {...props} />
    );
}

export const Large = allProps => {
    const { secondary, bold, ...props } = allProps;
    return (
        <p css={css`
        font-size: large;
        display: inline;
        color: ${secondary ? 'grey' : 'black'};
        font-weight: ${ bold ? 'bold' : 'normal'};
     `} {...props} />
    )
}