
export class AppError extends Error {
    constructor(message) {
      super(message)
      this.code = 'app-error';
      this.message = message;
      Error.captureStackTrace(this, AppError)
    }
    
    toJSON() {
      return {
        error: {
          errorCode: this.code.toString(),
          errorMessage: this.message
        }
      }
    }
  }

  export class ServerError extends AppError {}
  