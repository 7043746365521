import React, { useState, useEffect } from 'react';
import { H1, H2, H3, P, Large, Small } from '../../components/Text';
import { PrimaryButton } from '../../components/Buttons';
import { ClassicSpinner } from 'react-spinners-kit';

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TextInput, SubmitInput } from '../../components/Form';
import { Link, useLocation } from 'react-router-dom';

import { Header } from '../../components/Header';

import logoFull from '../../assets/logo_header_full.png';
import illustration from '../../assets/welcome_bg.png';
import { Row, Column } from '../../components/Layout';
import { Card } from '../../components/Card';

import { useHistory } from 'react-router-dom';
import useSession from '../../common/state/useSession';
import useAuth, { InvalidAuthError, UserNotFoundError } from '../../common/state/useAuth';

import qs from 'qs';
import { api } from '../../api/api';
import { AppError } from '../../common/Errors';
import { Loading, LoadingBall } from '../../components/Loading';
import { useErrorBoundary } from 'use-error-boundary/lib/use-error-boundary';
import useToast from '../../common/state/useToast';
import { auth } from 'firebase';

export const RegisterForm = props => {

  const { user, signup } = useAuth();

  const [isValid, setValid] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorText, setErrorText] = useState('');

  const { showSuccess, showError } = useToast();

  useEffect(() => {

    if (props.email) {
      setEmail(props.email);
    }

    validate();
  });

  /**
   * Create the account and then sign in using the custom token.
   * @param {Event} event 
   */
  const onSubmit = async event => {
    event.preventDefault();
    setIsSigningIn(true);
    try {
      const response = await api.createAccount({email, password, userId: props.userId})
      await auth().signInWithCustomToken(response.customToken);
      setIsSigningIn(false);
    } catch (error) {
      if (error instanceof InvalidAuthError) {
        showError('Invalid email/password');
      } else if (error instanceof UserNotFoundError) {
        showError('User not found, try again.')
      } else {
        showError(error.message)
      }
      setIsSigningIn(false);
    }
    console.log(`The user is ${user}`)

  }

  const validate = () => {
    setValid(email.length > 0 && password.length > 0 && repeatPassword.length > 0 && repeatPassword === password);
  }

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const onEmailUpdated = event => {
    setEmail(event.target.value);
  };

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const onPasswordUpdated = event => {
    setPassword(event.target.value);
  };

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const onRepeatPasswordUpdated = event => {
    setRepeatPassword(event.target.value);
  };

  return (
    <form onSubmit={onSubmit} css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        `}>
      <TextInput type="email" placeholder="Email" disabled={props.email} value={props.email} onChange={onEmailUpdated} />
      <TextInput type="password" placeholder="Password" onChange={onPasswordUpdated} autoComplete="new-password" />
      <TextInput type="password" placeholder="Repeat password" onChange={onRepeatPasswordUpdated} autoComplete="new-password" />
      <SubmitInput
        isValid={isValid}
        type="submit"
        value={isSigningIn ? 'Creating account...' : 'Create account'}
      />
      {errorText.length > 0 &&
        <div css={css`padding: 5px 10px; color: #BA3F1D;`}>
          <P>{errorText}</P>
        </div>
      }
    </form>
  )
}

export const Register = props => {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');
  const { showError, showSuccess } = useToast();
  const [userId, setUserId] = useState('');

  /**
   * On first render, grab the params from the URL and ask the backend if they are ok. 
   * If an email is returned, populate it into the field and let the user register. 
   */
  useEffect(() => {
    checkRegistration();
  }, []);

  const checkRegistration = async () => {
    try {
      setLoadingMessage('Just one moment...');
      const id = qs.parse(location.search, { ignoreQueryPrefix: true }).id;
      setUserId(id);
      const registrationToken = qs.parse(location.search, { ignoreQueryPrefix: true }).token;
      if (id && registrationToken) {
        const response = await api.checkRegistration({ userId: id, registrationToken });
        console.log('Were here')
        setEmail(response.email);
        setLoadingMessage(null);
        showSuccess('Reek & Dalli details found. You can now set your password')
      } else {
        setLoadingMessage(null);
        showError('Invalid registration parameters. You don\'t have permission to register');
      }
    } catch (error) {
      setLoadingMessage(null);
      showError('Invalid registration parameters. You don\'t have permission to register');
    }
  }

  return (
    <div css={css`
            background-color: white;
            width: 100%;
            height: 100%;
        `}>

      <Header css={css`background-color: white;`}>
        <Column left middle>
          <img css={css`height: 40px;`} src={logoFull} />
        </Column>
        <Column css={css`
          flex-grow: 1;
          `} right>
          <ul css={css`
            width: fit-content;
            list-style: none;
            align-self:flex-end;

            > li a {
              text-decoration: none;
              color: #00B8BC;

              :not(:first-of-type) {
                  margin-left: 10px;
              }
              :hover {
                  color: #2F6E72;
              }
              }
            `}>
            <li>
              <a href="https://reekanddalli.com">Home</a>
              <a href="https://reekanddalliproperties.com">All properties</a>
            </li>
          </ul>
        </Column>
      </Header>

      {/* The login box */}
      <Column center
        css={css`
                width: 100%;
                background-repeat: no-repeat;
            `}>
        <Column css={css`
          margin-top: 8em;`}>
          <Column center>
            <H2 css={css`color: black;margin: 0;`}>Owner Dashboard</H2>
            <H3 css={css`color: gray;margin: 0;`}>Register</H3>
          </Column>
          <img css={css`height: 80px; width: auto;`} src={illustration} />
          <div css={css`
              background: #FFFFFFD8;
              border-radius: 10px;
              box-shadow: 2px 2px 20px #86868666;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              `}
          >
            {loadingMessage && <Loading message={loadingMessage}/>}
            {email && <RegisterForm email={email} userId={userId}/>}
          </div>
        </Column>
      </Column>

      {/* Simple footer at the bottom */}
      <footer css={css`
        position: absolute;
        margin: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        bottom:0;
        margin-bottom: 1em;`}>
        <Small secondary bold>© Reek & Dalli 2020</Small>
        <Small secondary>Version: {process.env.npm_package_version}</Small>
      </footer>
    </div >
  );
}
export default Register;