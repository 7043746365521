import { getState } from "./Context";

const useToast = () => {
  const [state, setState] = getState();

  /**
   * @param {import("lib").Toast} toast 
   */
  const setToast = toast => {
    setState(state => ({ ...state, toast }));
  }

  const showSuccess = message => {
      setToast({message, type: 'success'})
  }

  const showError = (message, where) => {
      setToast({message, type: 'error'})
  }

  const clearToast = () => {
    setToast(null)
  }

  return {
    toast: state.toast,
    setToast,
    showSuccess,
    showError,
    clearToast
  }
};

export default useToast;