import React from 'react';
import { Row } from './Layout';
import { P, Large } from './Text';
const AttachmentIcon = './../assets/icons/attachment.svg';

// below comment line is required
// it tells babel how to convert properly
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

export const Field = ({ icon, label, text }) => {
    return (<Row css={css`margin: 10px;`}>
        <img src={icon} />
        <Large css={css`margin: 0 0 0 15px;`}>{label}</Large>
        <Large css={css`margin: 0 0 0 15px;`} bold>{text}</Large>
    </Row>);
};

export const Attachment = ({ attachment }) =>
    (<a css={css`text-decoration: none;`} href={attachment.url}>
        <div css={css`
            border-radius: 5px;
            background-color: #91ECD751;
            margin-right: 10px;
            padding: 2px 6px;
            width: fit-content;
            border: 1px solid #91ECD7;
            display: flex;
            :hover {
                opacity: 0.8;
            }
            `}>
            <img src={AttachmentIcon} />
            <P css={css`color: #4F8078;font-size: 10pt;`} bold>{attachment.name}</P>
        </div>
    </a>);
