import React from 'react';
import { P } from './Text';
import { DominoSpinner, ClassicSpinner } from 'react-spinners-kit';

/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Column } from './Layout';

/**
 * Loading indicator (full screen)
 * @param {{message?: string}} props
 */
export const Loading = ({ message }) => (
    <Column css={css`padding:1em;align-items:center;`} center>
        <DominoSpinner />
        <P css={css`text-align: center;`} secondary>Loading</P>
        {message && <P secondary>{message}</P>}
    </Column>
);

/**
 * Other Loading indicator
 * @param {{message?: string}} props
 */
export const LoadingBall = ({ message }) => (
    <Column css={css`padding:1em;align-items:center;`} center>
        <ClassicSpinner size={40} color="#01B8BC" />
        <P css={css`text-align: center;`} secondary>Loading</P>
        {message && <P secondary>{message}</P>}
    </Column>
);

