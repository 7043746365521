import React, { useState } from 'react'
import { Column, Row } from '../../../components/Layout'
import { H1, P, H4, H2, H3 } from '../../../components/Text'
import { Card } from '../../../components/Card';

const DownloadIcon = '../../../assets/icons/download.svg';
const CloseIcon = '../../../assets/icons/close.svg';
const CalendarIcon = '../../../assets/icons/calendar.svg';
const TenantIcon = '../../../assets/icons/tenant.svg';
const NightIcon = '../../../assets/icons/night.svg';
const EuroIcon = '../../../assets/icons/euro.svg';
const TransferIcon = '../../../assets/icons/transfer.svg';
const AttachmentIcon = '../../../assets/icons/attachment.svg';
const ReekAndDalliLogo = '../../../assets/logo_header.png';
const AirbnbLogo = '../../../assets/icons/airbnb.png';
const HomeAwayLogo = '../../../assets/icons/homeaway.png';
const BookingComLogo = '../../../assets/icons/booking.png';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import useExpensesPopup from '../../../common/state/usePopup';
import { ImageButton } from '../../../components/Buttons';
import { Table, TableRow, TableCell, TableHeader } from '../../../components/Table';
import useCalendar from '../../../common/state/useCalendar';
import moment from 'moment';
import { Field, Attachment } from '../../../components/Field';

const TextBox = ({text, onChange}) => (
    <input type="text" value={text} placeholder="No comments" css={css`
        padding: 10px; 
        border-radius: 10px;
         border: 2px solid #D9D9D9;
         outline: none;
         font-size: 10pt;
         margin: 1em;
        `}
        onChange={onChange}/>
)

/**
 *  Get the source form the api and transform it to a badge. 
 */
export const Source = (props) => {

    let source = props.source;
    let sourceLogo = ReekAndDalliLogo;
    
    if (source === 'AirbnbIntegration') {
        source = "AirBnB";
        sourceLogo = AirbnbLogo;
    } else if (source === "Manual" || source === "OH") {
        source = "Reek & Dalli bookings portal";
        sourceLogo = ReekAndDalliLogo;
    } else if (source === "BookingCom") {
        source = "booking.com";
        sourceLogo = BookingComLogo;
    }

    return (
        <span css={css`display:flex;`}>
            <img css={css`height: 30px;padding: 0 0.5em;`} src={sourceLogo} />
            <P>Booked from: {source}</P>
        </span> 
    )
}

/**
 * Expenses Popup
 * @param {{dateRange: import('rdd-shared').Booking}} param0 
 */
export const BookedDateRangePopup = ({ dateRange }) => {

    const numberOfNights = Math.abs(moment(dateRange.startDate, "YYYY-MM-DD").diff(moment(dateRange.endDate, "YYYY-MM'DD"), 'days'));

    const [editedComment, setEditedComment] = useState(dateRange.comment);
    const onCommentUpdated = (event) => {
        setEditedComment(event.target.value);
    }


    return (
        <Column>
            <Column middle>
                <H2>{numberOfNights} nights • {dateRange.numberOfGuests} guests</H2>
                <P css={css`margin-left: 15px;`} secondary>ID: {dateRange.id}</P>
                <Source source={dateRange.source}/>
            </Column>
            <Row>
                <Column>
                    <Field icon={CalendarIcon} label="Checkin date" text={moment(dateRange.startDate, 'YYYY-MM-DD').format('DD MMM YYYY')} />
                    <Field icon={CalendarIcon} label="Checkout date" text={moment(dateRange.endDate, 'YYYY-MM-DD').format('DD MMM YYYY')} />
                    <Field icon={CalendarIcon} label="Booked" text={moment(dateRange.bookedDate, 'YYYY-MM-DD').format('DD MMM YYYY')} />
                    <Field icon={NightIcon} label="Number of nights" text={numberOfNights} />
                    {/* <TextBox text={editedComment} onChange={onCommentUpdated} /> */}
                </Column>
                <Column>
                    <Field icon={TenantIcon} label="Tenant name" text={dateRange.guest.name} />
                    <Field icon={TenantIcon} label="Number of guests" text={dateRange.numberOfGuests} />
                    <Field icon={EuroIcon} label="Owner share" text="Coming soon" />
                    <Field icon={TransferIcon} label="Estimated payout date" text={moment(dateRange.payoutDate, 'YYYY-MM-DD').format('DD MMM YYYY')} />
                    <Field icon={AttachmentIcon} label="Attachments (coming soon)" />
                    {dateRange.attachments.map(attachment=>(
                        <Attachment attachment={attachment} />
                    ))}
                </Column>
            </Row>
        </Column>
    )
}

/**
 * Expenses Popup
 * @param {{dateRange: import('rdd-shared').OwnerStayDateRange}} param0 
 */
export const OwnerStayDateRangePopup = ({ dateRange }) => {

    return (
        <Row>
            <Column>
                <H1>{dateRange.startDate} {dateRange.endDate}</H1>
                <H1>{dateRange.startDate} {dateRange.endDate}</H1>
                <P>Range</P>
            </Column>
        </Row>
    )
}

/**
 * Expenses Popup
 * @param {{dateRange: import('rdd-shared').BlockedDateRange}} param0 
 */
export const BlockedDateRangePopup = ({ dateRange }) => {

    return (
        <Row>
            <Column>
                <H2>Blocked</H2>
                <H3>from {moment(dateRange.startDate).format('DD MMM YYYY')} to {moment(dateRange.endDate).format('DD MMM YYYY')}</H3>
            </Column>
        </Row>
    )
}

/**
 * Expenses Popup
 * @param {{dateRange: import('rdd-shared').DateRange}} param0 
 */
export const DateRangePopup = ({ dateRange }) => {
    const { setSelectedRange } = useCalendar();

    const type = dateRange.type;

    return (
        <Card css={css`margin: auto;max-width: 70%;padding: 3em;`}>
            <ImageButton onClick={() => setSelectedRange(undefined)} css={css`position: absolute; align-self: flex-end;`} src={CloseIcon} />
            {type === 'ownerStay' && <BlockedDateRangePopup dateRange={dateRange} />}
            {type === 'blocked' && <BlockedDateRangePopup dateRange={dateRange} />}
        </Card>
    )
}

/**
 * Expenses Popup
 * @param {{dateRange: import('rdd-shared').Booking}} param0 
 */
export const BookingPopup = ({ dateRange }) => {
    const { setSelectedRange } = useCalendar();
    return (
        <Card css={css`margin: auto;max-width: 70%;padding: 3em;`}>
            <ImageButton onClick={() => setSelectedRange(undefined)} css={css`position: absolute; align-self: flex-end;`} src={CloseIcon} />
            <BookedDateRangePopup dateRange={dateRange} />
        </Card>
    )
}